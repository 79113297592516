import { Alert, Button, Card, TextField } from '@mui/material';
import { Formik, FormikErrors } from 'formik';
import { CSSProperties, useState } from 'react';
import useLocalStorage from '../state/useStorage';
import './SettingsPage.css';

interface SettingValues {
  loadmaster: string;
  blueOneKey: string;
}

function SettingsPage() {
  const [loadmaster, setLoadmaster] = useLocalStorage<string>('loadmaster', '');
  const [blueOneKey, setBlueOneKey] = useLocalStorage<string>('blueOneKey', '');

  const valid = loadmaster && blueOneKey;

  const [saved, setSaved] = useState(!!valid);

  const phProps: CSSProperties = {
    marginLeft: 'auto',
    marginRight: '1em',
  };

  const getKeyFromUrl = (text: string): string => {
    let key: string = text;

    if (text.includes('?')) {
      key = text.substring(text.indexOf('?key=') + 5);
    }

    return key;
  };

  return (
    <div className='settings-page'>
      <Formik
        initialValues={{
          loadmaster: loadmaster ?? '',
          blueOneKey: blueOneKey ?? '',
        }}
        onSubmit={(values, actions) => {
          setLoadmaster(values.loadmaster);
          setBlueOneKey(values.blueOneKey);

          setSaved(true);
        }}
        validate={(values: SettingValues) => {
          let errors: FormikErrors<SettingValues> = {};

          if (!values.loadmaster) errors.loadmaster = 'please enter your name';

          if (!values.blueOneKey || values.blueOneKey.length < 16)
            errors.blueOneKey = 'please enter your BlueOne iCal key';

          if (errors.blueOneKey || errors.loadmaster) setSaved(false);

          return errors;
        }}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit}>
            <Card className='settings-form'>
              <TextField
                name='loadmaster'
                label='Loadmaster Name'
                value={form.values.loadmaster}
                onChange={form.handleChange}
                error={form.errors.loadmaster !== undefined}
                helperText={form.errors.loadmaster}
              />
              <TextField
                name='blueOneKey'
                label='BlueOne Key'
                value={form.values.blueOneKey}
                onChange={form.handleChange}
                onBlur={(e) =>
                  form.setFieldValue(
                    'blueOneKey',
                    getKeyFromUrl(e.currentTarget.value)
                  )
                }
                error={form.errors.blueOneKey !== undefined}
                helperText={form.errors.blueOneKey}
              />
              <div>
                <p>
                  You can find your iCal key{' '}
                  <a
                    href='https://goc.blueonesoftware.cloud/gocops/crew/crew.home?page=rosterical'
                    target='_blank'
                    rel='noreferrer'
                  >
                    here
                  </a>
                  .
                </p>
              </div>
              <div className='settings-form-button-row'>
                {saved ? (
                  <Alert
                    severity='success'
                    id='status'
                  >
                    Saved
                  </Alert>
                ) : (
                  <div style={phProps}></div>
                )}
                <Button
                  variant='contained'
                  type='submit'
                  fullWidth={false}
                  disabled={!form.isValid}
                >
                  Save
                </Button>
              </div>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SettingsPage;
