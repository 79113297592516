import { useState } from "react";

function useLocalStorage<T>(key: string, initialValue?: T):[T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        const item = window.localStorage.getItem(key);

        if (item || initialValue) {
          return item ? JSON.parse(item) : initialValue;
        } else {
          return undefined;
        }
        
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });
    
  const setValue = (value: any) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key,JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }

export default useLocalStorage;