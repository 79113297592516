function getUtcNow(time?: string) {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
    hour12: false,
  }).format(time ? new Date(time) : new Date().getTime());
}

export default getUtcNow;
