import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import AppBar from './AppBar';
import SettingsPage from './pages/SettingsPage';
import RosterPage from './pages/RosterPage';
import TripReportPage from './pages/TripReportPage';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <header>
          <div className='app-bar-sticky'>
            <AppBar />
          </div>
        </header>

        <main>
          <Routes>
            <Route
              path='/'
              element={<RosterPage />}
            />
            <Route
              path='/TripReport'
              element={<TripReportPage id={101} />}
            />
            <Route
              path='/Settings'
              element={<SettingsPage />}
            />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
