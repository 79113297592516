import './TripReportPage.css';
import { Form, Formik, useFormikContext } from 'formik';
import {
  Card,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import generateTripReport from '../utils/generateTripReport';
import { PersistFormikValues } from 'formik-persist-values';
import getUtcNow from '../utils/getUtcNow';
import { useLocation } from 'react-router-dom';
import useLocalStorage from '../state/useStorage';
import { useEffect } from 'react';
import { FlightInfo } from '../common/FlightInfo';

interface TripReportProps {
  id: number;
}

function TripReportPage(props: TripReportProps) {
  const data = useLocation().state as FlightInfo;
  const [loadmaster] = useLocalStorage('loadmaster');

  const InitReport = () => {
    const form = useFormikContext();

    useEffect(() => {
      if (data) {
        form.resetForm();

        form.setFieldValue('fltDate', data.depDate);
        form.setFieldValue('fltNum', data.fltNum);
        form.setFieldValue('aircraft', data.tail);
        form.setFieldValue('route', `${data.from}-${data.to}`);
        form.setFieldValue('etd', data.skd);
        form.setFieldValue('loadmaster', loadmaster);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
  };

  return (
    <div className='trip-report-form'>
      <Formik
        initialValues={{
          // Flight Information
          fltDate: '',
          fltNum: '',
          aircraft: '',
          route: '',
          etd: '',
          loadmaster: '',
          captain: '',

          // Critical Briefing Items
          maxACL: '',
          revCargoWeight: '',
          nonrevWeight: '',
          ttlLoad: '',
          zfw: '',
          zfwCg: '',
          tow: '',
          towCg: '',

          // Arrival
          blockIn: '',
          arrCargoDoorOpened: '',
          arrOffloadStart: '',
          arrOffloadDone: '',
          arrOffloadStaff: '',
          arrCargoDoorClosed: '',
          arrPpkStocked: '',

          // Departure
          depDeadloadRecvd: '',
          depLoadPlanDone: '',
          depCargoDoorOpened: '',
          depLoadingStart: '',
          depLoadingDone: '',
          depCargoDoorClosed: '',
          depCargoDocsRecvd: '',
          depNotocRecvd: '',
          depCrewDoorClose: '',
          depPushback: '',
          depOnloadStaff: '',

          // Remarks
          remarks: '',

          // Cross Check
          cc1_1: false,
          cc1_2: false,
          cc1_3: false,
          cc1_4: false,
          cc1_5: false,
          cc2_1: false,
          cc2_2: false,
          cc2_3: false,
          cc2_4: false,
          cc2_5: false,
        }}
        onSubmit={async (values, actions) => {
          const pdfblob = await generateTripReport(
            JSON.stringify(values, null, 2)
          );

          const ourl = URL.createObjectURL(
            new Blob([pdfblob], { type: 'application/pdf' })
          );

          window.open(ourl, '_blank');

          URL.revokeObjectURL(ourl);
        }}
      >
        {(form) => (
          <Form onSubmit={form.handleSubmit}>
            <PersistFormikValues name='trip-report-form' />
            <InitReport />
            <Card className='trip-report-card'>
              <span className='card-header'>Flight Information</span>
              <div>
                <TextField
                  id='fltDate'
                  name='fltDate'
                  type='text'
                  label='Flight Date'
                  onChange={form.handleChange}
                  value={form.values.fltDate}
                />
              </div>
              <div>
                <TextField
                  id='fltNum'
                  name='fltNum'
                  type='text'
                  label='Flight Number'
                  onChange={form.handleChange}
                  value={form.values.fltNum}
                />
              </div>
              <div>
                <TextField
                  id='aircraft'
                  name='aircraft'
                  type='text'
                  label='Aircraft'
                  onChange={form.handleChange}
                  value={form.values.aircraft}
                />
              </div>

              <div>
                <TextField
                  id='route'
                  name='route'
                  type='text'
                  label='Route'
                  onChange={form.handleChange}
                  value={form.values.route}
                />
              </div>

              <div>
                <TextField
                  id='etd'
                  name='etd'
                  type='text'
                  label='ETD'
                  onChange={form.handleChange}
                  value={form.values.etd}
                />
              </div>

              <div>
                <TextField
                  id='loadmaster'
                  name='loadmaster'
                  type='text'
                  label='Loadmaster'
                  onChange={form.handleChange}
                  value={form.values.loadmaster}
                />
              </div>

              <div>
                <TextField
                  id='captain'
                  name='captain'
                  type='text'
                  label='Captain'
                  onChange={form.handleChange}
                  value={form.values.captain}
                />
              </div>
            </Card>

            <Card className='trip-report-card'>
              <span className='card-header'>Critical Briefing Items</span>
              <div>
                <TextField
                  id='maxACL'
                  name='maxACL'
                  type='text'
                  label='Flight Plan Payload'
                  onChange={form.handleChange}
                  value={form.values.maxACL}
                />
              </div>
              <div>
                <TextField
                  id='revCargoWeight'
                  name='revCargoWeight'
                  type='text'
                  label='Revenue Cargo Weight'
                  onChange={form.handleChange}
                  value={form.values.revCargoWeight}
                />
              </div>
              <div>
                <TextField
                  id='nonrevWeight'
                  name='nonrevWeight'
                  type='text'
                  label='Nonrev/COMAT Weight'
                  onChange={form.handleChange}
                  value={form.values.nonrevWeight}
                />
              </div>

              <div>
                <TextField
                  id='ttlLoad'
                  name='ttlLoad'
                  type='text'
                  label='Total Load'
                  onChange={form.handleChange}
                  value={form.values.ttlLoad}
                />
              </div>

              <div>
                <TextField
                  id='zfw'
                  name='zfw'
                  type='text'
                  label='ZFW'
                  onChange={form.handleChange}
                  value={form.values.zfw}
                />
              </div>

              <div>
                <TextField
                  id='zfwCg'
                  name='zfwCg'
                  type='text'
                  label='ZFW CG'
                  onChange={form.handleChange}
                  value={form.values.zfwCg}
                />
              </div>

              <div>
                <TextField
                  id='tow'
                  name='tow'
                  type='text'
                  label='TOW'
                  onChange={form.handleChange}
                  value={form.values.tow}
                />
              </div>

              <div>
                <TextField
                  id='towCg'
                  name='towCg'
                  type='text'
                  label='TOW CG'
                  onChange={form.handleChange}
                  value={form.values.towCg}
                />
              </div>
            </Card>

            <Card className='trip-report-card'>
              <span className='card-header'>Arrival Information</span>
              <div>
                <TextField
                  id='blockIn'
                  name='blockIn'
                  type='text'
                  label='Block In'
                  onChange={form.handleChange}
                  value={form.values.blockIn}
                />

                <Button
                  onClick={() => form.setFieldValue('blockIn', getUtcNow())}
                >
                  Now
                </Button>
                <Button onClick={() => form.setFieldValue('blockIn', 'N/A')}>
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='arrCargoDoorOpened'
                  name='arrCargoDoorOpened'
                  type='text'
                  label='Cargo Doors Opened'
                  onChange={form.handleChange}
                  value={form.values.arrCargoDoorOpened}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('arrCargoDoorOpened', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() =>
                    form.setFieldValue('arrCargoDoorOpened', 'N/A')
                  }
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='arrOffloadStart'
                  name='arrOffloadStart'
                  type='text'
                  label='Offload Start'
                  onChange={form.handleChange}
                  value={form.values.arrOffloadStart}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('arrOffloadStart', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('arrOffloadStart', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='arrOffloadDone'
                  name='arrOffloadDone'
                  type='text'
                  label='Offload Done'
                  onChange={form.handleChange}
                  value={form.values.arrOffloadDone}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('arrOffloadDone', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('arrOffloadDone', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='arrCargoDoorClosed'
                  name='arrCargoDoorClosed'
                  type='text'
                  label='Cargo Doors Closed'
                  onChange={form.handleChange}
                  value={form.values.arrCargoDoorClosed}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('arrCargoDoorClosed', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() =>
                    form.setFieldValue('arrCargoDoorClosed', 'N/A')
                  }
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='arrOffloadStaff'
                  name='arrOffloadStaff'
                  type='text'
                  label='Offload Staff'
                  onChange={form.handleChange}
                  value={form.values.arrOffloadStaff}
                />

                <Button
                  onClick={() => form.setFieldValue('arrOffloadStaff', 'N/A')}
                >
                  N/A
                </Button>
                <Button disabled></Button>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                PPK at least 50%?
                <RadioGroup
                  style={{ flexDirection: 'row' }}
                  id='arrPpkStocked'
                  name='arrPpkStocked'
                  value={form.values.arrPpkStocked}
                  onChange={form.handleChange}
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio />}
                    label='Yes'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio />}
                    label='No'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </div>
            </Card>

            <Card className='trip-report-card'>
              <span className='card-header'>Departure Information</span>
              <div>
                <TextField
                  id='depDeadloadRecvd'
                  name='depDeadloadRecvd'
                  type='text'
                  label='Deadload Recvd'
                  onChange={form.handleChange}
                  value={form.values.depDeadloadRecvd}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depDeadloadRecvd', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depDeadloadRecvd', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depLoadPlanDone'
                  name='depLoadPlanDone'
                  type='text'
                  label='Load Plan Done'
                  onChange={form.handleChange}
                  value={form.values.depLoadPlanDone}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depLoadPlanDone', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depLoadPlanDone', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depCargoDoorOpened'
                  name='depCargoDoorOpened'
                  type='text'
                  label='Cargo Doors Opened'
                  onChange={form.handleChange}
                  value={form.values.depCargoDoorOpened}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depCargoDoorOpened', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() =>
                    form.setFieldValue('depCargoDoorOpened', 'N/A')
                  }
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depLoadingStart'
                  name='depLoadingStart'
                  type='text'
                  label='Loading Start'
                  onChange={form.handleChange}
                  value={form.values.depLoadingStart}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depLoadingStart', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depLoadingStart', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depLoadingDone'
                  name='depLoadingDone'
                  type='text'
                  label='Loading Done'
                  onChange={form.handleChange}
                  value={form.values.depLoadingDone}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depLoadingDone', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depLoadingDone', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depCargoDoorClosed'
                  name='depCargoDoorClosed'
                  type='text'
                  label='Cargo Doors Closed'
                  onChange={form.handleChange}
                  value={form.values.depCargoDoorClosed}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depCargoDoorClosed', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() =>
                    form.setFieldValue('depCargoDoorClosed', 'N/A')
                  }
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depCargoDocsRecvd'
                  name='depCargoDocsRecvd'
                  type='text'
                  label='Cargo Docs Recvd'
                  onChange={form.handleChange}
                  value={form.values.depCargoDocsRecvd}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depCargoDocsRecvd', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depCargoDocsRecvd', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depNotocRecvd'
                  name='depNotocRecvd'
                  type='text'
                  label='NOTOC Recvd'
                  onChange={form.handleChange}
                  value={form.values.depNotocRecvd}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depNotocRecvd', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depNotocRecvd', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depCrewDoorClose'
                  name='depCrewDoorClose'
                  type='text'
                  label='Crew Door Closed'
                  onChange={form.handleChange}
                  value={form.values.depCrewDoorClose}
                />

                <Button
                  onClick={() =>
                    form.setFieldValue('depCrewDoorClose', getUtcNow())
                  }
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depCrewDoorClose', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depPushback'
                  name='depPushback'
                  type='text'
                  label='Pushback'
                  onChange={form.handleChange}
                  value={form.values.depPushback}
                />

                <Button
                  onClick={() => form.setFieldValue('depPushback', getUtcNow())}
                >
                  Now
                </Button>
                <Button
                  onClick={() => form.setFieldValue('depPushback', 'N/A')}
                >
                  N/A
                </Button>
              </div>

              <div>
                <TextField
                  id='depOnloadStaff'
                  name='depOnloadStaff'
                  type='text'
                  label='Onload Staff'
                  onChange={form.handleChange}
                  value={form.values.depOnloadStaff}
                />

                <Button
                  onClick={() => form.setFieldValue('depOnloadStaff', 'N/A')}
                >
                  N/A
                </Button>
                <Button disabled></Button>
              </div>
            </Card>

            <Card className='trip-report-card-remarks'>
              <span className='card-header'>Remarks</span>

              <TextField
                id='remarks'
                name='remarks'
                type='text'
                multiline
                fullWidth
                minRows='3'
                maxRows='5'
                onChange={form.handleChange}
                value={form.values.remarks}
              />
            </Card>

            <Card className='trip-report-card'>
              <span className='card-header'>Critical Briefing Items</span>
              <div>
                <FormControlLabel
                  label='Is the revenue cargo weight more than the authorized flight plan payload weight?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc1_1}
                  onChange={() =>
                    form.setFieldValue('cc1_1', !form.values.cc1_1)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Have any airplane or flight plan limits been exceeded?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc1_2}
                  onChange={() =>
                    form.setFieldValue('cc1_2', !form.values.cc1_2)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Are there any voided positions?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc1_3}
                  onChange={() =>
                    form.setFieldValue('cc1_3', !form.values.cc1_3)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Has the bulk compartment been visually checked and weights are correct?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc1_4}
                  onChange={() =>
                    form.setFieldValue('cc1_4', !form.values.cc1_4)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Are there any dangerous goods onboard?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc1_5}
                  onChange={() =>
                    form.setFieldValue('cc1_5', !form.values.cc1_5)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='(MD-11) Is the fuel system in the standard fuel ratio?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc2_1}
                  onChange={() =>
                    form.setFieldValue('cc2_1', !form.values.cc2_1)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='(B744) Is fuel system MEL 28-31-04A applied?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc2_2}
                  onChange={() =>
                    form.setFieldValue('cc2_2', !form.values.cc2_2)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Are there any air system DMIs?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc2_3}
                  onChange={() =>
                    form.setFieldValue('cc2_3', !form.values.cc2_3)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='Are there any cargo loading system DMIs?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc2_4}
                  onChange={() =>
                    form.setFieldValue('cc2_4', !form.values.cc2_4)
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  label='If a NOTOC is required, has a copy been sent to notoc@westernglobal.aero?'
                  labelPlacement='end'
                  control={<Checkbox />}
                  checked={form.values.cc2_5}
                  onChange={() =>
                    form.setFieldValue('cc2_5', !form.values.cc2_5)
                  }
                />
              </div>
            </Card>

            <div className='card-button-row'>
              <div>
                <Button
                  variant='contained'
                  type='button'
                  onClick={(e) => (confirmReset() ? form.handleReset(e) : '')}
                >
                  Reset
                </Button>
              </div>
              <div>
                <Button
                  variant='contained'
                  type='submit'
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function confirmReset() {
  return window.confirm('Are you sure you wish to start over?');
}

export default TripReportPage;
