import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlightInfo } from '../common/FlightInfo';
import './RosterCard.css';

const RosterCard = (flt: FlightInfo) => {
  let navigate = useNavigate();

  const handleClick = () => {
    if (window.confirm(`Start new report for ${flt.fltNum} on ${flt.depDate}?`))
      navigate('/TripReport', { state: { ...flt } });
  };

  return (
    <Card
      className='roster-card'
      onClick={handleClick}
    >
      <div className='roster-card-col-a'>
        <div>{flt.fltNum}</div>
        <div>{flt.tail}</div>
      </div>
      <div className='roster-card-col-b'>
        <div>
          {flt.from} - {flt.to}
        </div>
      </div>
      <div className='roster-card-col-c'>
        <div>{flt.depDate}</div>
        <div>
          {flt.skd} - {flt.ska}
        </div>
      </div>
    </Card>
  );
};

export default RosterCard;
