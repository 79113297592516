import { Fab, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { FlightInfo } from '../common/FlightInfo';
import useLocalStorage from '../state/useStorage';
import RosterCard from './RosterCard';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';

import './RosterPage.css';

function RosterPage() {
  const [roster, setRoster] = useState<FlightInfo[]>();
  const [blueOneKey] = useLocalStorage<string>('blueOneKey');
  const [flightList, setFlightList] = useLocalStorage('flightList', '');
  const UPDATE_INTERVAL = 10000;
  const [allowUpdate, setAllowUpdate] = useState(blueOneKey && blueOneKey.length > 16 && true); // quick and dirty key validation (check length)
  const fltRegExp =
    /BEGIN:VEVENT\nUID:([0-9]*)\nDTSTART:([a-zA-Z0-9]*)\nDTEND:([a-zA-Z0-9]*)\nSUMMARY:FLT - ([a-zA-Z0-9]*) ([a-zA-Z0-9]*)-([a-zA-Z0-9]*) ([a-zA-Z0-9]*)/g;

  const doUpdate = () => {
    setAllowUpdate(false);

    fetch(new URL(`https://purple-disk-3c15.flightdev.workers.dev/?key=${blueOneKey}`))
      .then((response) => response.text())
      .then((text) => {
        parseSkd(text);
      });
  };

  const clearFlights = () => {
    const clear: FlightInfo[] = [];
    setRoster(clear);
    setFlightList('');
  };

  const parseSkd = (value: string) => {
    const re = new RegExp(fltRegExp);
    let match: RegExpExecArray | null;
    let flights: FlightInfo[] = [];

    do {
      match = re.exec(value);

      if (match) {
        let f: FlightInfo = {};
        f.uid = match[1];
        f.fltNum = match[4];
        f.from = match[5];
        f.to = match[6];
        f.skd = fixBlueOneTime(match[2]);
        f.ska = fixBlueOneTime(match[3]);
        f.tail = match[7];
        f.depDate = fixBlueOneTime(match[2], true);
        flights.push(f);
      }
    } while (match);

    if (flights.length > 0) {
      setRoster(flights);
      setFlightList(JSON.stringify(flights));
    }
  };

  const fixBlueOneTime = (time: string, dateOnly: boolean = false) => {
    // 2022 08 29 T 0  4  3  0  0  0  Z
    // 0123 45 67 8 9 10 11 12 13 14 15

    const yyyy = time.substring(0, 4);
    const mm = time.substring(4, 6);
    const dd = time.substring(6, 8);
    const hh = time.substring(9, 11);
    const min = time.substring(11, 13);

    //let fixedDate = `${yyyy}-${mm}-${dd}T${hh}:${min}`;

    if (dateOnly) {
      let mmm = '';

      switch (mm) {
        case '01':
          mmm = 'JAN';
          break;

        case '02':
          mmm = 'FEB';
          break;

        case '03':
          mmm = 'MAR';
          break;

        case '04':
          mmm = 'APR';
          break;

        case '05':
          mmm = 'MAY';
          break;

        case '06':
          mmm = 'JUN';
          break;

        case '07':
          mmm = 'JUL';
          break;

        case '08':
          mmm = 'AUG';
          break;

        case '09':
          mmm = 'SEP';
          break;

        case '10':
          mmm = 'OCT';
          break;

        case '11':
          mmm = 'NOV';
          break;

        case '12':
          mmm = 'DEC';
          break;

        default:
          mmm = 'UNK';
          break;
      }

      return (`${dd}${mmm}${yyyy.substring(yyyy.length - 2)}`);
    }

    return (`${hh}:${min}`);
  };

  useEffect(() => {
    const nextUpdate = setInterval(() => {
      setAllowUpdate(blueOneKey && true);
    }, UPDATE_INTERVAL);

    return () => clearInterval(nextUpdate);
  });

  useEffect(() => {
    if (flightList) {
      let flights: FlightInfo[] = [];

      flights = JSON.parse(flightList);

      setRoster(flights);
    }
  }, [flightList]);

  const fabRefreshStyle: SxProps = {
    position: 'fixed',
    right: 16,
    top: 66,
  };

  const fabDeleteStyle: SxProps = {
    position: 'fixed',
    right: 16,
    top: 130,
  };

  const hasRoster = () => {
    if (roster && roster?.length) {
      return true;
    }

    return false;
  };

  return (
    <div className='roster-list'>
      <Fab sx={fabRefreshStyle} size='large' type='button' onClick={doUpdate} disabled={!allowUpdate}>
        <RefreshIcon fontSize='large' color='primary' />
      </Fab>

      <Fab sx={fabDeleteStyle} size='large' type='button' onClick={clearFlights}>
        <DeleteIcon fontSize='large' color='primary' />
      </Fab>

      {hasRoster() ? roster?.map((o) => <RosterCard {...o} key={o.uid} />) : 'No flights found.'}
    </div>
  );
}

export default RosterPage;
