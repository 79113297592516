import './AppBar.css';
import { Link } from 'react-router-dom';

function AppBar() {

    return (
        <div className='app-bar'>
            <div><Link to='/'>Roster</Link></div>
            <div>|</div>
            <div><Link to='/TripReport'>Trip Report</Link></div>
            <div><Link to='/Settings'>Settings</Link></div>
        </div>
    );
}

export default AppBar;